// assets/js/app.js

require('../css/font-awesome.min.css');
require('../css/bootstrap.min.css');
require('../css/App.css');
require('../css/skins/skin-red.css');
require('../plugins/iCheck/square/blue.css');


const $ = require('jquery');
global.$ = global.jQuery = $;


require('../js/bootstrap.min.js');
require('../plugins/slimScroll/jquery.slimscroll.min.js');
require('../plugins/iCheck/icheck.min.js');
require('../js/gest.js');